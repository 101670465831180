.search-page {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

.search-container {
    width: 88vw;
    height: 70vh;
}

.search-app {
    border: 7px solid #283B62;
    border-radius: 10px;
}