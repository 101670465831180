.title-card {
    color: #283b62;
    margin-top: 20px;
}

.title {
    text-align: center;
    font-weight: bold;
    letter-spacing: .4rem;
}

.title-content {
    display: flex;
}

.guide-image {
    max-height: 500px;
    margin: 20px;
}

.guide-text {
    padding: 20px;
    display: flex;
    align-items: center;
}

.guide-heading {
    font-weight: bold;
    width: 100%;
}

.guide-description {
    width: 100%;
}

@media  screen and (max-width: 1200px) {
    .title-content {
        flex-direction: column;
    }
}