.nav-container {
    display: flex;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 2px 10px black;
    box-shadow: 2px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 900;
}

.navbar {
    color: white;
    /* width: 80%; */
    
  }

.logo {
    background-color: transparent;
  }

  
@media only screen and (max-width: 950px) {
    .nav-items {
      display: none; /* Hide nav items by default */
      flex-direction: column; /* Stack items vertically when open */
      background-color: white;
      position: absolute;
      top: 60px; /* Adjust as per your design */
      right: 0;
      width: 100%;
      text-align: right;
      padding: 20px;
      box-shadow: 0px 2px 10px black;
      
    }
}