.resource-comp {
    width: 358px;
    height: 420px;
    position: relative;
    background: #F6F6F6; 
    border-radius: 32px;
    overflow: hidden; 
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.resource-title {
    top: 186px; 
    position: absolute; 
    text-align: center;
    color: #181818;
    font-size: 20px;
    font-weight: 600;
    word-wrap: break-word;
}
/* Original font-family Inter */
.type-of-service {
    width: 359px; 
    left: -1px; 
    top: 222px;
    position: absolute;
    text-align: center;
    color: #808080;
    font-size: 15px;
    font-weight: 400;
    word-wrap: break-word;
}
.resource-button {
    width: 203px;
    height: 60px;
    padding-left: 32px; 
    padding-right: 32px;
     padding-top: 16px;
    padding-bottom: 16px; 
    left: 78px;
    top: 292px; 
    position: absolute;
    background: linear-gradient(180deg, #1A1A1A 0%, #131313 100%);
    border-radius: 9px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    cursor:pointer;
    text-decoration: none;
}
.resource-pic {
    text-decoration: none;
  }
  
.send {
    width: 24px;
    height: 24px;
    position: relative;
    
}

.button-title {
    color: white;
    font-size: 18px;
    font-weight: 500; 
    word-wrap: break-word;
}

.resource-image {
    width: 254px; 
    height: 113px;
    left: 52px;
    top: 51px;
    position: absolute;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
}