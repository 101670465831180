@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.pages-container {
    display: flex;
    justify-content: center;
}

.pages {
    width: 100%;
}

.App {
    font-family: 'Montserrat', 'Open Sans', sans-serif;
    height: 100vh;
    display: flex;
    flex-direction: column;
}