@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.footer {
    background-color: #283b62;
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    padding: 50px;
    overflow: visible;
    color: white;
    margin-top: auto;
  }

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}

.footer .footer-left {
    width: 30%;
    text-align: center;
    justify-content: center;
  }

  .profile-wrapper {
    height: 180px;
    width: 180px;
    margin: auto;
  }

  .profile-wrapper img {
    border-radius: 80%;
  }
  
  .footer .about {
    line-height: 20px;
    color: #ffffff;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
  }
  
  .footer .about span {
    display: block;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .footer .icons {
    margin-top: 25px;
  }
  
  .footer .icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-right: 3px;
    margin-bottom: 5px;
  }
  
  /* footer center*/
  .footer .footer-center {
    width: 33%;
    margin-top: 3%;
  }

  .footer-center > div {
    padding: 10px;
  }
  
  .footer .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
  }
  
  .footer .footer-center i.fa-envelope {
    font-size: 12px;
    line-height: 38px;
  }
  
  .footer .footer-center p {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
  }
  
  .footer .footer-center p span {
    display: block;
    font-weight: thin;
    font-size: 17px;
    line-height: 1.5;
  }
  
  .footer .footer-center p a {
    color: #0099ff;
    text-decoration: none;
  }
  
  /* footer right*/
  .footer .footer-right {
    width: 35%;
    margin-top: 3%;
  }
  
  .footer h2 {
    color: #ffffff;
    font-size: 36px;
    font-weight: normal;
    margin: 0;
  }
  
  .footer h2 span {
    color: #0099ff;
  }
  
  .footer .menu {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
  }
  
  .footer .menu a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
  }
  
  .footer .menu a:hover {
    color: #0099ff;
  }
  
  .footer .name {
    color: #0099ff;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }

  .footer .about-header {
    font-family: 'Roboto';
  }
  
  @media screen and (max-width: 790px) {
    .footer {
      font-size: 14px;
    }

    .footer .footer-left,
    .footer .footer-center,
    .footer .footer-right {
      display: block;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
    }

    .footer .footer-center {
      margin-left: 0;
    }
  }
  
  @media screen and (max-width: 1110px) {
    .footer-center > div {
      margin: auto;
      text-align: center;
    }

    .footer-center > div > svg {
      width: 100%;
    }

    .footer-center > div > a > svg {
      width: 100%;
    }
  }
  