
.heading {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}
.heading h1{
    color: #283b62;
    font-weight: bold;
    letter-spacing: .2rem;
    text-align: center;
}