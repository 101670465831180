@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Global styles */
.nav-container {
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 2px 10px black;
  position: sticky;
  top: 0;
  z-index: 900;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content within the navbar */
  width: 100%;
  color: white;
  position: relative; /* Relative positioning for the logo */
  height: 100px; /* Set a height to accommodate the larger logo */
  padding: 0 20px; /* Add padding to ensure space on the sides */
  z-index: 900;
}

/* Ensure the logo has a fixed size */
.logo {
  display: block;
  position: absolute;
  left: 20px; /* Adjust this value to position the logo correctly */
  top: 50%; /* Vertically center the logo */
  transform: translateY(-50%); /* Vertically center the logo */
  height: 95px; /* Set the height of the logo */
  width: auto; /* Maintain aspect ratio */
}

/* Hamburger icon initially hidden */
.hamburger-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: #283b62;
  position: absolute;
  right: 20px; /* Position the hamburger icon on the right side */
  top: 50%; /* Vertically center the hamburger icon */
  transform: translateY(-50%); /* Vertically center the hamburger icon */
}

/* Navigation items */
.nav-items {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the nav items */
  flex-grow: 1; /* Allow nav items to grow and fill the available space */
  margin-left: 120px; /* Adjust space for the larger logo */
  font-family: 'Roboto';
  text-transform: uppercase;
}

/* Show the menu when the hamburger icon is clicked */
.nav-items.open {
  display: block;
}

/* Individual nav items */
.nav-item {
  position: relative;
  padding: 10px 1vw;
  cursor: pointer;
  white-space: nowrap;
  flex-shrink: 0;
  overflow: visible;
}

.nav-item:hover:not(.dropdown > *) {
  color: #8b9bb4;
}

/* Dropdown styles */
.dropdown-container {
  position: absolute;
  padding: 0;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: #333;
  border-top: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  width: 200px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 0.3s, transform 0.3s;
  z-index: 1000;
  text-align: center;
}

.nav-item:hover .dropdown-container {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.dropdown {
  color: #283b62;
  background-color: white;
  padding: 10px;
  list-style: none;
}

.dropdown:hover {
  color: white;
  background-color: #283b62;
}

.nav-name {
  margin: auto;
  padding: auto;
  color: #283b62;
  font-size: 1.5vw;
}

.nav-item:hover {
  background-color: #283b62;
  border-radius: 4px;
}

.nav-item:hover > .nav-name {
  color: white;
}

.nav-item.active {
  background-color: #283b62;
  border-radius: 4px;
}

.nav-item.active > .nav-name {
  color: white;
}

.dropdown.active {
  color: white;
  background-color: #283b62;
}

.dropdown.active:hover {
  color: white;
}

/* Media query for screens 950px or less */
@media screen and (max-width: 950px) {

  .nav-items {
    display: none; /* Hide nav items by default */
    flex-direction: column; /* Stack items vertically when open */
    background-color: white;
    position: absolute;
    top: 100px; /* Adjust as per your design */
    right: 0;
    width: 100%;
    text-align: right;
    box-shadow: 0px 2px 10px black;
  }

  .nav-item {
    width: 100%;
    text-align: center;
  }

  .nav-items.open {
    display: flex; /* Show nav items when the hamburger menu is open */
    width: 70%;
    margin-top: 0.2px;
    padding-left: 0;
    padding-right: 0;
  }

  .nav-item.active {
    border-radius: 0;
  }

  .hamburger-icon {
    display: block; /* Show the hamburger icon */
  }

  .nav-name {
    font-size: 5vw;
  }
  
  /* Hide dropdown container items on small screens */
  .dropdown-container {
    display: none; /* Hide the entire dropdown container */
  }
}

/* Media query for screens wider than 950px */
@media screen and (min-width: 951px) {
  .logo {
    display: block; /* Ensure the logo is visible on wider screens */
  }

  .hamburger-icon {
    display: none; /* Hide the hamburger icon on wider screens */
  }

  .nav-items {
    display: flex; /* Show nav items on wider screens */
    margin-left: 120px; /* Adjust space for the larger logo */
  }
}


