@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,body{
    width: 100%;
    height: 100%;
  }
  
  .containerhori{
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
    font-family: 'Roboto';
  }
  .containerhori a{
    text-decoration: none;
  }

 .name-container{
    display:flex;
    width:100%;
    height: 100%;
    justify-content: center;
    align-items: center;
 }

  .guide-wrap {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 85vh;
  }
  
  .guide {
    flex: 1;
    height: 100%;
    width:100%;
    background-position: center;
    background-size: cover;
    background-repeat: none;
    transition: flex 0.8s ease;
  }
  .guide:hover{
    flex: 7;
  }  
  .name-container:hover .section-name {
    transform: rotate(0deg);
    transform-origin: center;
}

  .section-name {  
    transform: rotate(-90deg);
    transition: transform 0.5s ease;       
    margin: 0;
    white-space: nowrap;
    font-weight:bold;
    color: white;
    text-shadow: 2px 2px 0px black, 4px 4px 0px rgba(0,0,0,0.25);
  }

/*This is for the home page horizontal accordion  */
  .home-name{
    white-space: nowrap;
    font-size: 3.5rem;
    font-weight:bold;
    letter-spacing: 0.5rem;
    color: white;
    text-shadow: 2px 2px 0px black, 4px 4px 0px rgba(0,0,0,0.25);
  }

  @media screen and (max-width: 680px) {
    .containerhori{
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
  }
  .containerhori a{
    text-decoration: none;
  }

 .name-container{
    display:flex;
    width:100%;
    height: 100%;
    justify-content: center;
    align-items: center;
 }

  .guide-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85vh;
  }
  
  .guide {
    flex: 1;
    height: 100%;
    width:100%;
    background-position: center;
    background-size: cover;
    background-repeat: none;
  }
  .guide:hover{
    flex: 7;
  }  
  .name-container:hover .section-name {
    transform: rotate(0deg);
    transform-origin: center;
}

  .section-name {  
    transform: rotate(0deg);
    transition: transform 0.5s ease;       
    margin: 0;
    white-space: nowrap;
    font-weight:bold;
    color: white;
    text-shadow: 2px 2px 0px black, 4px 4px 0px rgba(0,0,0,0.25);
  }
  }