.heading {
    text-align: center;
    margin-top: 20px;
}

.heading h1 {
    color: #283b62;
    font-weight: bold;
    letter-spacing: .2rem;
}

.content-container {
    display: flex;
    justify-content: center;
    margin: 15px;
}

.house-logo {
    width: 22%;
}

.mission-statement {
    width: 40%;
    margin: 10% 5%;
    font-size: 18px;
}

.contact-button-container {
    margin: 30px;
}

.contact-button {
    color: #283b62;
    font-size: 30px;
    font-weight: bold;
    padding: .5% 7%;
    background-color: transparent;
    border: 4px solid #283b62;
    transition: background-color .2s ease-out 5ms;
    transition: border-color .2s ease-out 5ms;
}

.contact-button:hover {
    color: white;
    background-color: #95a7cc;
    border-color: #95a7cc;
}

@media screen and (max-width: 500px) {
    .house-logo {
        display: none;
    }

    .mission-statement {
        font-size: 1rem;
    }
    
    .contact-button-container {
        margin: 30% auto;
    }

    .contact-button {
        font-size: 1.5rem;
    }
}