.thankSubmit h1{
    margin: 5% auto;
    font-size: 3rem;
    text-align: center;
    color: #283b62;
    font-weight: bold;
    letter-spacing: .2rem;
}

.thankSubmit p {
    width: 80%;
    margin: 5% auto;
    text-align: center;
    font-size: 1.5rem;
    color: #283b62;
}

@media screen and (max-width: 450px) {
    .thankSubmit h1 {
        margin: 10% auto;
        font-size: 2rem;
    }

    .thankSubmit p {
        margin: 10% auto;
        font-size: 1.2rem;
    }
}