.guide-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.guide-content {
    width: 80%;
}

.accordion_container {
    margin-bottom: 25px;
    color: #283b62;
}