.contact-page{
    color: #283B62;
    display: flex;
    flex-direction: column;
}

p {
    font-size: 17px;
}
h6 {
    font-size: 18px;
}

.heading {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}
.heading h1{
    color: #283b62;
    font-weight: bold;
    letter-spacing: .2rem;
    text-align: center;
}

.contact-container1 {
    width: 80%;
    align-self: center;
    display: inline-grid;
    justify-content: space-around;
    justify-items: center;
    grid-template-columns: auto auto;
    padding: 30px;
    row-gap: 20px;
}

.contact-info {
    justify-self: left;
}

.photo-container {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    width: 190px;
    height: auto;
}

.contact-photo {
    width: 100%;
    height:100%;
}

.contact-address > iframe {
    width: 400px;
    height: 300px;
}

.map-border {
    border: #283b62 3px solid;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius:3% ;
}

.form-container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.submit-button {
    width: fit-content;
    align-self: center;
    margin-top: 10px;
}

@media screen and (max-width: 1400px) {
    .form-container {
        width: 80%;
    }
}

@media screen and (max-width: 950px) {
    .photo-container    { grid-area: pic; }
    .contact-info       { grid-area: info; }
    .contact-address    { grid-area: address; }
    .form-container     { grid-area: form; }

    .contact-container1 {
        grid-template-areas:    'pic'
                                'info'
                                'address'
                                'form'
                                ;
        justify-content: center;
    }

    .contact-info {
        justify-self: center;
    }

    .contact-address {
        min-height: 50%;
        height: 350px;
    }

    .contact-address > iframe {
        width: 100%;
        height: 50%;
    }
}